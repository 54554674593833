import React from 'react'
import '../Style/ContactCards.css'
function ContactCard() {
  return (
    <section className='ContactCards'>
        <div className='Container'>
            <div className='ContactCards__wrapper'>
                <div className='ContactCard__card'>
                    <div className='ContactCard__card__svg'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25c1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02z"></path></svg>
                    </div>
                    <span>
                        Telefon
                    </span>
                    <a href="tel:+9712068686">(998) 99 476 84 95</a>
                </div>
                <div className='ContactCard__card'>
                    <div className='ContactCard__card__svg'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 4l-8 5l-8-5V6l8 5l8-5z"></path></svg>
                    </div>
                    <span>
                    Elektron pochta
                    </span>
                    <a href="https://mail.google.com/mail/u/0/#inbox">doniyorjonibekov1995@gmail.com</a>
                </div>
                <div className='ContactCard__card'>
                    <div className='ContactCard__card__svg'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><circle cx={12} cy={10} r={3}></circle><path d="M12 2a8 8 0 0 0-8 8c0 1.892.402 3.13 1.5 4.5L12 22l6.5-7.5c1.098-1.37 1.5-2.608 1.5-4.5a8 8 0 0 0-8-8"></path></g></svg>
                    </div>
                    <span>
                    Manzil
                    </span>
                    <a href="https://www.google.com/maps/search/gulistan+it+live+/@40.5113048,68.7012935,10z?entry=ttu&g_ep=EgoyMDI0MDkxMC4wIKXMDSoASAFQAw%3D%3D">Guliston sh,IT LIVE ACADEMY</a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ContactCard