import React, { useEffect } from 'react';
import Hero from '../Components/Hero';
import DemoChapterCard from '../Components/DemoChapterCard';
import ChapterCard from '../Components/ChapterCard';
import FAQ from '../Components/FAQ';

function Chapter() {
    const token = localStorage.getItem('token');

    useEffect(() => {
        const handleBeforeUnload = (event) => {  
            const message = 'Вы уверены, что хотите покинуть страницу? Все данные будут удалены.';
            event.returnValue = message; 
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <main>
            <Hero />
            {token ? <ChapterCard /> : <DemoChapterCard />}
            <FAQ />
        </main>
    );
}

export default Chapter;
