import React from 'react'
import AllResult from '../Components/AllResult'
import Hero from '../Components/Hero'

function Result() {
  return (
    <main className='Result'>
      <Hero title={`Umumiy natijalar`} text={`Natijangiz qoniqmayabsizmi? Unda yana urinib ko'ring`}/>
      <AllResult/>
    </main>
  )
}

export default Result