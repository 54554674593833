import React from 'react'
import ContactCard from '../Components/ContactCard'
import AboutUsInfo from '../Components/AboutUsInfo'
import Hero from '../Components/Hero'

function AboutUs() {
  return (
    <main>
      <Hero title={`Biz haqimizda`} text={`O‘zingizning kreativ fikrlash, bilimlarni hayotga bog‘lay olish ko‘nikmalaringizni biz bilan rivojlantiring`}/>
      <AboutUsInfo/>
      <ContactCard/>
    </main>
  )
}

export default AboutUs