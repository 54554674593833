import React, { useEffect, useState } from 'react';
import '../Style/HomeTest.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import axios from '../Service/axios';
import CONFIG from '../Service/Config';
import ReactLoading from 'react-loading';

function HomeTest() {
    const navigate = useNavigate();
    const Navigate = (id) => {
        navigate(`/collaction/${id}`)
    }


    
    const [loading, setLoading] = useState(true)
    const [allSubject, setAllSubject] = useState([])
    useEffect(() => {
          const getAllSubject = () => {
            axios.get(`/subject/getSubjectByEducId`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
            })
              .then((response) => {
                setAllSubject(response.data);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                setLoading(false);
              });
          };
          getAllSubject();
      }, []);

    const defoltFoto = 'https://static.abt.uz/crop/600__61064345cbf8d.jpg'

    if (loading) {
        return (
            <div className='Loading__mb'>
                <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
            </div>
        );
    }

    if (!allSubject || allSubject.length === 0) {
        return (
            <div className='HomeTest'>
                <div className='Container'>
                    <h2>
                        O'zingizni sinab ko'ring!
                    </h2>
                    <div className='Nores'>
                        <h2>
                            Fanlar yo`q
                        </h2>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='HomeTest'>
            <div className='Container'>
                <h2>
                    O'zingizni sinab ko'ring!
                </h2>
                <div className='HomeTest__swiper pc'>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={10}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {allSubject?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => Navigate(i.id)}>
                                <div className='HomeTest__Card'>
                                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                                    <div>
                                        <h3>
                                            {i.name}
                                        </h3>
                                        <p>
                                            Bolim soni:{i.collectionCount}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}

                        {/* Add additional SwiperSlide elements as needed */}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper dec'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {allSubject?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => Navigate(i.id)}>
                                <div className='HomeTest__Card'>
                                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                                    <div>
                                        <h3>
                                            {i.name}
                                        </h3>
                                        <p>
                                            Bolim soni:{i.collectionCount}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* Add additional SwiperSlide elements as needed */}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper mb'>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={50}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {allSubject?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => Navigate(i.id)}>
                                <div className='HomeTest__Card'>
                                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                                    <div>
                                        <h3>
                                            {i.name}
                                        </h3>
                                        <p>
                                            Bolim soni:{i.collectionCount}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* Add additional SwiperSlide elements as needed */}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper lc'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {allSubject?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => Navigate(i.id)}>
                                <div className='HomeTest__Card'>
                                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                                    <div>
                                        <h3>
                                            {i.name}
                                        </h3>
                                        <p>
                                         Bolim soni:{i.collectionCount}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default HomeTest;
