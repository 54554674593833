import React from 'react';
import HomeDemoTest from '../Components/HomeDemoTest';
import Hero from '../Components/Hero';
import HomeTest from '../Components/HomeTest';
import FAQ from '../Components/FAQ';

function Home() {
  const token = localStorage.getItem('token');

  return (
    <main>
      
      <Hero title='TMozaika interaktiv o‘yinlari orqali 
      Fan modullari bo‘yicha IQni aniqlash' text='O‘zingizning kreativ fikrlash, bilimlarni hayotga bog‘lay olish ko‘nikmalaringizni biz bilan rivojlantiring
' />
      {token ? <HomeTest /> : <HomeDemoTest />}
      <FAQ />
    </main>
  );
}

export default Home;
