import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../Style/Login.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Login({ show, handleClose }) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')

  
  const showSuccessToast = () => {
    toast.success('Muvaffaqiyatli!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };


  const showErrorToast = () => {
    toast.error('Xato!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };


  const Login = (e)=>{
    e.preventDefault()
    const newData ={
      phoneNumber:phoneNumber,
      password:password,

    }
    axios.post('/user/login',newData)
    .then((response)=>{
      setPassword('')
      setPhoneNumber('')
      showSuccessToast()
      localStorage.setItem('token', response.data.object)
      setTimeout(() => {
        handleClose();
    }, 1000);
    })
    .catch((error)=>{
      showErrorToast()
      console.log(error);
    })
  }
  return (
    <Modal className='Login' show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Kirish</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={Login}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Telefon raqam</Form.Label>
            <Form.Control
            value={phoneNumber}
            onChange={(e)=>setPhoneNumber(e.target.value)}
            type="number" placeholder="Telefon raqam" />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Parol</Form.Label>
            <Form.Control
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            type="password" placeholder="Parol" />
          </Form.Group>

          <Button variant="primary" type="submit" className="Login__btn mt-3">
            Kirish
          </Button>
        </Form>
      </Modal.Body>
      <ToastContainer/>
    </Modal>
  );
}

export default Login;
