import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppLayout from './layouts/AppLayout';
import MainLayout from './layouts/MainLayout';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import AboutUs from './Pages/AboutUs';
import Result from './Pages/Result';
import Chapter from './Pages/Chapter';
import Profile from './Pages/Profile';
import CollactionBySubject from './Pages/CollactionBySubject';
import Test from './Pages/Test';
import ProtectedRoute from './Components/ProtectedRoute';
function App() {


  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path='/' element={<AppLayout />}>
              <Route element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path='contact' element={<Contact />} />
                <Route path='aboutUs' element={<AboutUs />} />
                <Route path='result' element={<Result />} />
                <Route path='chapter/:ID' element={<Chapter />} />
                <Route path='test/:ID' element={<Test />} />
                <Route path='profil' element={<ProtectedRoute element={<Profile />} />} />
                <Route path='collaction/:ID' element={<CollactionBySubject />} />
              </Route>
            </Route>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
