import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/ChapterCard.css';
import { Link, useParams } from 'react-router-dom';
import axios from '../Service/axios';
import ReactLoading from 'react-loading';

function DemoChapterCard() {
    const { ID } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getLevel = () => {
            axios.get(`/level/getAllByCollectionId/${ID}`)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        getLevel();
    }, [ID]);

    if (loading) {
        return (
            <div className='Loading__mb'>
                <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
            </div>
        );
    }

    if (!data || data.length === 0) {
        return (
            <div className='ChapterCard'>
                <div className='Container'>
                    <div className='Nores'>
                        <h2>
                            Daraja yo'q
                        </h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <section className='ChapterCard'>
            <div className='Container'>
                <div className='ChapterCard__wrapper'>
                    {data.map((i, index) => (
                        <Link 
                            to={`/test/${i.id}`} 
                            state={{ cardData: i }}  // Passing card data as state
                            key={index}
                        >
                            <Card className="custom-card" style={{ width: '12rem', borderRadius: '10px', borderLeft: '5px solid blue' }}>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-start">
                                        <h2>{i.title}</h2>
                                    </div>
                                    <Card.Text className="text-muted">
                                        {i.educationName}
                                    </Card.Text>
                                    <Card.Text style={{ color: 'blue', fontWeight: 'bold' }}>
                                    {`Savol ${2 * (index + 1)} ta`} 
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default DemoChapterCard;
