import React, { useEffect, useState } from 'react';
import '../Style/AllResult.css';
import { Table, Pagination, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../Service/axios';
import CONFIG from '../Service/Config';
import ReactLoading from 'react-loading';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

function AllResult() {
  const [loading, setLoading] = useState(true);
  const [allSubject, setAllSubject] = useState([]);
  const [reting, setReting] = useState([]); // Initialize as empty array

  useEffect(() => {
    const getAllSubject = () => {
      axios.get(`/subject/getSubjectByEducId`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          setAllSubject(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getAllSubject();
  }, []);

  const defoltFoto = 'https://static.abt.uz/crop/600__61064345cbf8d.jpg';
  const GetReting = (id) => {
    axios.get(`/user/rating/${id}`,)
      .then((response) => {
        const data = response.data.content;
        if (Array.isArray(data)) {
          setReting(data.map(item => ({
            ...item,
            age: item.age || ''
          })));
        } else {
          setReting([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setReting([]);
      })
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = reting.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.age.toString().includes(searchTerm)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  
  if (loading) {
    return (
      <div className='Loading__mb'>
        <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
      </div>
    );
  }   

  if (!allSubject || allSubject.length === 0) {
    return (
      <div className='HomeTest'>
        <div className='Container'>
          <h2>
            O'zingizni sinab ko'ring !
          </h2>
          <div className='Nores'>
            <h2>
              Fanlar yo`q
            </h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='HomeTest'>
        <div className='Container'>
          <div className='HomeTest__swiper pc'>
            <Swiper
              slidesPerView={4}
              spaceBetween={10}
              navigation={true}
              loop={false}
              modules={[Navigation]}
              className="mySwiper"
            >
              {allSubject?.map((i, index) => (
                <SwiperSlide key={index} onClick={() => GetReting(i.id)}>
                  <div className='HomeTest__Card'>
                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                    <div>
                      <h3>{i.name}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className='HomeTest__swiper dec'>
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation={true}
              loop={false}
              modules={[Navigation]}
              className="mySwiper"
            >
              {allSubject?.map((i, index) => (
                <SwiperSlide key={index} onClick={() => GetReting(i.id)}>
                  <div className='HomeTest__Card'>
                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                    <div>
                      <h3>{i.name}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className='HomeTest__swiper mb'>
            <Swiper
              slidesPerView={2}
              spaceBetween={50}
              navigation={true}
              loop={false}
              modules={[Navigation]}
              className="mySwiper"
            >
              {allSubject?.map((i, index) => (
                <SwiperSlide key={index} onClick={() => GetReting(i.id)}>
                  <div className='HomeTest__Card'>
                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                    <div>
                      <h3>{i.name}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className='HomeTest__swiper lc'>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              navigation={true}
              loop={false}
              modules={[Navigation]}
              className="mySwiper"
            >
              {allSubject?.map((i, index) => (
                <SwiperSlide key={index} onClick={() => GetReting(i.id)}>
                  <div className='HomeTest__Card'>
                    <img src={i.imgName ? CONFIG.API_URL + i.imgName : defoltFoto} alt="Foto" />
                    <div>
                      <h3>{i.name}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <section className='AllResult'>
        <div className='Container'>
          <Form className="mb-4">
            <Form.Group controlId="search">
              <Form.Control
                type="text"
                placeholder="Search by name or age"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </Form>
       <div className='Table'>
       <Table  >
            <thead>
              <tr>
                <th>№</th>
                <th>F.I.O</th>
                <th>Region</th>
                <th>IQ</th>
                <th>Foiz</th>
                <th>Ball</th>
                <th>Kalit</th>
                <th>Urinishlar</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.name} {item.surname}</td>
                  <td>{item.region}</td>
                  <td>{item.iq}</td>
                  <td>{item.percent}</td>
                  <td>{item.ball_count}</td>
                  <td>{item.key_count}</td>
                  <td>{item.total_attempts}</td>
                </tr>
              ))}
            </tbody>
          </Table>
       </div>

          {/* Pagination */}
          <div className="d-flex justify-content-end">
            <Pagination>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            </Pagination>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllResult;
