import React, { } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import '../Style/HomeFAQ.css';

function FAQ() {

    return (
        <section className='HomeFAQ'>
            <div className='Container'>
                <h2>Tez-tez so'raladigan savollar</h2>
                <div className='HomeFAQ__wrapper'>
                    <div className='HomeFAQ__accordion'>
                        <Accordion >
                            <Card>
                                <Accordion.Header eventKey="0">
                                    <h6>
                                        Platforma haqida ?
                                    </h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        IQ test platformasi sizga IQ darajangizni aniqlashda yordam beradi, yoshingizdan qat'i nazar.</p>
                                </Accordion.Body>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Accordion.Header eventKey="1">
                                    <h6>
                                        Testlar qanday shaklda olib boriladi
                                    </h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Test jarayonida 5 ta darajadan o‘tishingizga to‘g‘ri keladi. Har bir darajada sizga 3 ta savol beriladi.
                                    </p>
                                </Accordion.Body>
                            </Card>

                            {/* Add more Accordion items as needed */}
                        </Accordion>
                    </div>
                    <div className='HomeFAQ__accordion'>
                        <Accordion>
                            <Card>
                                <Accordion.Header eventKey="2">
                                    <h6>
                                        Platformadan qanday foydalanamiz ?
                                    </h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Platformadan ro‘yxatdan o‘tganingizdan so‘ng, siz uchun maxsus tayyorlangan testlarda ishtirok etishingiz mumkin. Test jarayoni tugagach, "Natijalar" bo‘limiga o‘tib, o‘z natijalaringizni va boshqalarning natijalarini ko‘rishingiz mumkin.
                                    </p>
                                </Accordion.Body>
                            </Card>


                            {/* Add more Accordion items as needed */}
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Accordion.Header eventKey="2">
                                    <h6>
                                        Test jarayonida yordam bo‘ladimi?
                                    </h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Agar siz maxsus tayyorlangan testda ishtirok etsangiz, sizda kalit bo‘ladi. Agar bitta savolga noto‘g‘ri javob bersangiz, kalit sizdan olib qo‘yiladi. Biroq, agar rasmli savollarga to‘g‘ri javob bersangiz, kalitni qayta qo‘lga kiritishingiz mumkin. Testlarda kalit sizga yordam beradi.
                                    </p>
                                </Accordion.Body>
                            </Card>


                            {/* Add more Accordion items as needed */}
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ;
