import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/Test.css';
import Hero from './Hero';
import axios from '../Service/axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CONFIG from '../Service/Config';
import ReactLoading from 'react-loading';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Info, LevelProvider } from '../layouts/AppLayout';
import { AppLayoutContext } from '../layouts/AppLayout';

const getTTL = (setTime) => {
    axios.get('/attempts/getUserTTL', {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    })
        .then((response) => {
            setTime(response.data.object);
        })
        .catch((error) => {
            console.log(error);
        });
};

function RealTest() {
    const { ID } = useParams();
    const location = useLocation();
    const { cardData, index: passedIndex, subjectId } = location.state || {};

    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userAnswer, setUserAnswer] = useState([]);
    const { currentLevel, setCurrentLevel } = useContext(LevelProvider);
    const { setRender, setTime } = useContext(AppLayoutContext);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { setInformation } = useContext(Info)
    const [timeLeft, setTimeLeft] = useState(60);
    const [shouldNavigate, setShouldNavigate] = useState(true);

    useEffect(() => {
        setInformation(subjectId)
        if (currentLevel !== passedIndex && passedIndex !== undefined) {
            setTimeout(() => {
                navigate(-1);
            }, 1000);
        }
    }, [currentLevel, passedIndex, navigate, subjectId, setInformation]);

    useEffect(() => {
        axios.get(`/question/getQuestionByLevelId/${ID}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => {
                setQuestions(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                getTTL(setTime);
            });
    }, [ID, setTime]);


    useEffect(() => {
        const Question = () => {
            axios.post(`/attempts/useKey/${subjectId}`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((response) => {
                const otv = response.data.object;
                if (otv === true) {
                    setShouldNavigate(true); 
                    setTimeLeft(60);
                    setRender((prev) => prev + 1);
                } else {
                    setShouldNavigate(false);
                    TimeError();
                    setTimeout(() => {
                        navigate(-1);
                    }, 1000);
                }
            })
            .catch((error) => {
                const notAttemp = ()=>{
                    const notData = {
                        userAnswer: null,
                        questionId: 2,
                    }
                    axios.post(`/attempts/create`, notData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    })
                    .then((response)=>{

                    })
                    .catch((error)=>{

                    })
                }
                notAttemp()
                setShouldNavigate(false);
                TimeError(error.response?.data?.message);
                setCurrentLevel(0)
 
                setTimeout(() => {
                    navigate(-1);
                }, 1000);
            });
        };
        if (timeLeft === 0) {
            Question();
        } else {
            if(!loading){
                const timer = setInterval(() => {
                    setTimeLeft((prev) => prev - 1);
                }, 1000);
                return () => clearInterval(timer);
            }
        }
    }, [timeLeft, navigate, subjectId, setRender, loading, setCurrentLevel, ]); 
    useEffect(() => {
        if (shouldNavigate) {
            setTimeLeft(60);
        }
    }, [shouldNavigate]); 
    useEffect(() => {
        setTimeLeft(60);
    }, [currentQuestionIndex]);
    

    


    const handleAnswerChange = (e) => {
        const { value } = e.target;
        setUserAnswer(value);
    };

    const handleTextInputChange = (e, index) => {
        const { value } = e.target;

        setUserAnswer((prev) => {
            const updatedAnswers = [...prev];
            updatedAnswers[index] = value;
            return updatedAnswers.join('');
        });

        if (value.length === 1 && index < questions[currentQuestionIndex].correctAnswerLength - 1) {
            document.getElementById(`input-${index + 1}`)?.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        const { key } = e;
        if (key === 'Backspace' && e.target.value === '' && index > 0) {
            document.getElementById(`input-${index - 1}`)?.focus();
        } else if (key === 'ArrowRight' && index < questions[currentQuestionIndex].correctAnswerLength - 1) {
            document.getElementById(`input-${index + 1}`)?.focus();
        } else if (key === 'ArrowLeft' && index > 0) {
            document.getElementById(`input-${index - 1}`)?.focus();
        }
    };

    const Attempt = (e) => {
        e.preventDefault();
        setTimeLeft(60);
        setIsButtonDisabled(true);
    
        if (!questions.length) return;
    
        const currentQuestion = questions[currentQuestionIndex];
        if (!currentQuestion) return;

        const answer = {
            userAnswer: userAnswer ? userAnswer : null,
            questionId: currentQuestion.id,
        };
    
        axios.post(`/attempts/create`, answer, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => {
                const ServeAnswer = response.data.object;
                setRender((prev) => prev + 1);
                if (currentQuestionIndex === questions.length - 1) {
                    if (ServeAnswer === true) {
                        finish();
                        setCurrentLevel((prev) => prev + 1);
                    } else {
                        showErrorToast();
                        setTimeout(() => {
                            navigate(-1);
                        }, 2000);
                    }
                } else {
                    if (ServeAnswer === true) {
                        showSuccessToast();
                        setTimeout(() => {
                            setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
                        }, 1000);
                    } else {
                        showErrorToast();
                        setTimeout(() => {
                            navigate(-1);
                        }, 2000);
                    }
                }
                getTTL(setTime);
                setUserAnswer('');
            })
            .catch((error) => {
                AnswerError(error.response.data.message);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 1000);
            });
    };
    

    if (loading) {
        return (
            <div className='Loading__mb'>
                <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
            </div>
        );
    }

    if (!questions || questions.length === 0) {
        return (
            <div className='Test'>
                <div className='Container'>
                    <div className='Nores NoresTest'>
                        <h2>Savol yo`q</h2>
                    </div>
                </div>
            </div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    const showSuccessToast = () => {
        toast.success(`To'g'ri!`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const showErrorToast = () => {
        toast.error('Xato!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };
    const TimeError = (message) => {
        toast.error(message || 'Vaqt tugadi!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const AnswerError = (message) => {
        toast.error(message || `Xato!`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const finish = () => {
        toast.success(`Tabriklaymiz, siz ${cardData.title} tugatdingiz.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    return (
        <main>
            <Hero />
            <section className='Test'>
                <div className='Container'>
                    <div className='Test__wrapper'>
                        <div className='card__wrraperr'>
                            {currentQuestion && (
                                <div className='card__wrraperr' key={currentQuestion.id}>
                                    {currentQuestion.questionType === 'TEST' ? (
                                        <div className='Test__Card'>
                                            <div className='Test__card__grid'>
                                                <h2>{currentQuestion.questionValue}</h2>
                                                <div className='Test__Card__otv'>
                                                    {currentQuestion.additiveAnswer.map((answer, index) => (
                                                        <label htmlFor={`answer-${index}`} key={index}>
                                                            <input
                                                                type="radio"
                                                                id={`answer-${index}`}
                                                                name={`question-${currentQuestion.id}`}
                                                                value={answer}
                                                                onChange={handleAnswerChange}
                                                            />
                                                            <span>{answer}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='Test__card__grid'>
                                                <div className='Test__Card__grid__time'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 4a1 1 0 0 0-1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V7a1 1 0 0 0-1-1"></path></g></svg>
                                                    {timeLeft}
                                                </div>
                                                <div className='Test__card__grid__btn'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={Attempt}
                                                        disabled={isButtonDisabled} // Disable button when `isButtonDisabled` is true
                                                    >
                                                        Keyngisi..
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : currentQuestion.questionType === 'IMAGE' ? (
                                        <div className='Test__Card'>
                                            <div className='Test__card__grid Test__card__grid2'>
                                                <img src={CONFIG.API_URL + currentQuestion.imgUrl} alt="question-img" />
                                                <div>
                                                    <span className='test__card__text2'>{currentQuestion.questionValue}</span>
                                                    <div className='Test__card__Bkv'>
                                                        {Array.from({ length: currentQuestion.correctAnswerLength }).map((_, index) => (
                                                            <input
                                                                key={index}
                                                                id={`input-${index}`}
                                                                type="text"
                                                                className="form-control single-letter-input"
                                                                maxLength="1" // Одна буква
                                                                value={userAnswer[index] || ''}
                                                                onChange={(e) => handleTextInputChange(e, index)}
                                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Test__card__grid'>
                                                <div className='Test__Card__grid__time'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 4a1 1 0 0 0-1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V7a1 1 0 0 0-1-1"></path></g></svg>
                                                    {timeLeft}
                                                </div>
                                                <div className='Test__card__grid__btn'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={Attempt}
                                                        disabled={isButtonDisabled} // Disable button when `isButtonDisabled` is true
                                                    >
                                                        Keyngisi..
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='Test__Card'>
                                            <h2>No question type found</h2>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </main>
    );
}

export default RealTest;
