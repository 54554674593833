import React from 'react'
import '../Style/Hero.css'
function Hero({title, text}) {
    return (
        <section className='HomeHero'>
            <div className='Container'>
                <h1>
                   {title}
                </h1>
                <p>
                   {text}
                </p>
            </div>
        </section>
    )
}

export default Hero