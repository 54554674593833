import React, { useContext, useEffect, useState } from 'react';
import '../Style/HomeTest.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import axios from '../Service/axios';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Info, LevelProvider } from '../layouts/AppLayout';

function CollactionBySubjectItems() {
    const { setCurrentLevel} = useContext(LevelProvider)
    const { setInformation } = useContext(Info);
    const navigate = useNavigate();
    const { ID } = useParams();
    const [collaction, setCollaction] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setInformation(ID || null);

        const getAllSubject = () => {
            axios.get(`/collection/getCollectionsBySubjectId/${ID}`, {
                params: { isDemo: false },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((response) => {
                setCollaction(response.data[0]);
                setCurrentLevel(0)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
        };

        getAllSubject();
    }, [ID, setInformation, setCurrentLevel]);



    
    if (loading) {
        return (
          <div className='Loading__mb'>
            <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
          </div>
        );
    }

    const handleItemClick = (id, isFinished) => {
        if (!isFinished) {
            navigate(`/chapter/${id}`,{ state: { subjectId: ID } });
        }
    };

    return (
        <div className='HomeTest'>
            <div className='Container'>
                <h2>O'zingizni sinab ko'ring!</h2>
                <div className='HomeTest__swiper pc'>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={10}
                        navigation={true}
                        loop={false}
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => handleItemClick(i.id, i.isFinished)}>
                                <div className={`HomeTest__Card DemoCard2 ${i.isFinished ? 'finished' : ''}`}>
                                    <div>
                                        <h3>{i.title}</h3>
                                        <span>{i.description}</span>
                                        <h5>{i.isFinished ? 'Tugatilgan' : 'Tugallanmagan'}</h5>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper dec'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        navigation={true}
                        loop={false}
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => handleItemClick(i.id, i.isFinished)}>
                                <div className={`HomeTest__Card DemoCard2 ${i.isFinished ? 'finished' : ''}`}>
                                    <div>
                                        <h3>{i.title}</h3>
                                        <span>{i.description}</span>
                                        <h5>{i.isFinished ? 'Tugatilgan' : 'Tugallanmagan'}</h5>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper mb'>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={50}
                        navigation={true}
                        loop={false}
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => handleItemClick(i.id, i.isFinished)}>
                                <div className={`HomeTest__Card DemoCard2 ${i.isFinished ? 'finished' : ''}`}>
                                    <div>
                                        <h3>{i.title}</h3>
                                        <span>{i.description}</span>
                                        <h5>{i.isFinished ? 'Tugatilgan' : 'Tugallanmagan'}</h5>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper lc'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        navigation={true}
                        loop={false}
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={() => handleItemClick(i.id, i.isFinished)}>
                                <div className={`HomeTest__Card DemoCard2 ${i.isFinished ? 'finished' : ''}`}>
                                    <div>
                                        <h3>{i.title}</h3>
                                        <span>{i.description}</span>
                                        <h5>{i.isFinished ? 'Tugatilgan' : 'Tugallanmagan'}</h5>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default CollactionBySubjectItems;
