import React from 'react'
import '../Style/AboutUsInfo.css'
function AboutUsInfo() {
    return (
        <section className='AboutUsInfo'>
            <div className='Container'>
                <h1>
                    Biz haqimizda
                </h1>
                <p>
                    <span>
                        💠Ushbu platforma:</span> Guliston Davlat Universiteti tayanch doktaranti Jonibekov Doniyor Baxtiyor o'g'lining 13.00.02 Talim va tarbiya nazariyasi va metodikasi (informatika) ixtisosligi bo'yicha "Axborotlashtirilgan ta'lum muhitida aqliy bilish darajasini aniqlashda didaktik o'yinlardan foydalanish metodikasini takomillashtirish" mavzusidagi pedagigika fanlari bo'yicha falsafa doktori (PhD) dissertatsiya ishi.
                </p>
               <div className='AboutusInfo__text'>
               <span>
                    💠 Maqsadi va vazifalari:
                </span>
                <p>
                    ✅ Masofaviy ta’lim tizimida zamonaviy axborot texnologiyalaridan foydalanib, didaktik topshiriqlarni yaratish metodlari, usullari va undan samarali foydalanish, aqliy bilish darajasini aniqlashda didaktik o‘yin texnologiyalarini takomillashtirish.
                </p>
                <p>
                    ✅ Ushbu loyiha asosida Xalqaro baholash PISA, TIMSS kabi dasturlarda ishtirok etuvchi o'quvchilarning mantiqiy fikrlash qobiliyatini rivojlantiradi va hayotga qo'lay olishni o'rgatadi.
                </p>
                <p>
                    ✅ Bundan tashqari barcha yosh toifasidagi foydalanuvchilarga ham tavsiya etiladi. Bu ilovaning yana bir yutuq tomoni shundaki, dasturdan barcha fan o'qituvchilari foydalana olishlari mumkin, chunki dasturga topshiriqlarni o'qituvchining o'zi mustaqil tuzib joylay oladi.
                </p>
               </div>
                <p>
                    <span>
                        💠 Kelgusi rejalar: </span> Kelajakda loyihani yanada kengroq va foydalanishga qulay qilish uchun mobil aloqa vositalari, internet tarmog'i orqali masofali boshqarish imkoniyatini kengaytirish, kontent materiallarini boyitish ustida ish olib borish va foydalanuvchilar fikri va takliflari asosida yanada qulay va kop imkoniyatlilikni oshirish.
                </p>
            </div>
        </section>
    )
}

export default AboutUsInfo