import React, { useState, useEffect } from 'react';
import axios from '../Service/axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ProfileModal({ show, handleClose }) {



    
    const showSuccessToast = () => {
        toast.success('Muvaffaqiyatli o`zgartirildi!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      };
    
    
      const showErrorToast = () => {
        toast.error('Xato!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      };
    
    const [edit, setEdit] = useState({
        name: '',
        phoneNumber: '',
        surname: '',
        region: ''
    });

    const getMyInformation = () => {
        axios.get(`/user/getCurrenInformation/${0}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => {
                setEdit({
                    name: response.data.name || '',
                    phoneNumber: response.data.phoneNumber || '',
                    surname: response.data.surname || '',
                    region: response.data.region || ''
                });
                
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getMyInformation();
    }, []);

    const editInfo = (e) => {
        e.preventDefault(); // Prevent default form submission

        // Only send fields that are filled out (required or optional)
        axios.put('/user/updateInformation', {
            name: edit.name || undefined,
            phoneNumber: edit.phoneNumber || undefined,
            password: edit.password || undefined,
            surname: edit.surname || undefined,
            region: edit.region || undefined
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then((response) => {
            showSuccessToast()
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        })
        .catch((error) => {
            showErrorToast()
            console.log(error);
        });
    };

    return (
        <Modal className='Register' show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>O'zgartirish</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={editInfo}>
                    <Form.Group controlId="name">
                        <Form.Label>Ism</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={edit.name}
                            onChange={(e) => setEdit({ ...edit, name: e.target.value })}
                            placeholder="Ism"
                        />
                    </Form.Group>

                    <Form.Group controlId="Surname">
                        <Form.Label>Familiya</Form.Label>
                        <Form.Control
                            type="text"
                            name="surname"
                            value={edit.surname}
                            onChange={(e) => setEdit({ ...edit, surname: e.target.value })}
                            placeholder="Familiya"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Telefon raqam</Form.Label>
                        <Form.Control
                            type="number"
                            name="phoneNumber"
                            value={edit.phoneNumber}
                            onChange={(e) => setEdit({ ...edit, phoneNumber: e.target.value })}
                            placeholder="Telefon raqam"
                        />
                    </Form.Group>

                    <Form.Group controlId="exampleSelect">
                        <Form.Label>Sizning viloyatingiz</Form.Label>
                        <Form.Select
                            value={edit.region}
                            onChange={(e) => setEdit({ ...edit, region: e.target.value })}
                            aria-label="Default select example"
                        >
                            <option value="">Viloyat</option> {/* Empty option for initial state */}
                            <option value="QORAQALPOGISTON">Qoraqalpogiston</option>
                            <option value="ANDIJON">Andijon</option>
                            <option value="BUXORO">Buxoro</option>
                            <option value="JIZZAX">Jizzax</option>
                            <option value="QASHQADARYO">Qashqadaryo</option>
                            <option value="NAVOIY">Navoiy</option>
                            <option value="NAMANGAN">Namangan</option>
                            <option value="SAMARQAND">Samarqand</option>
                            <option value="SURXANDARYO">Surxandaryo</option>
                            <option value="SIRDARYO">Sirdaryo</option>
                            <option value="TOSHKENT_VIL">Toshkent viloyat</option>
                            <option value="TOSHKENT_SHAX">Toshkent shaxar</option>
                            <option value="XORAZM">Xorazm</option>
                            <option value="FARGONA">Farg'ona</option>
                        </Form.Select>
                    </Form.Group>

                    {/* <Form.Group controlId="formBasicPassword">
                        <Form.Label>Parol</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={edit.password}
                            onChange={(e) => setEdit({ ...edit, password: e.target.value })}
                            placeholder="Parol"
                        />
                    </Form.Group> */}

                    <Button variant="primary" type="submit" className="Login__btn mt-3">
                        O'zgartirishni Saqlash
                    </Button>
                </Form>
            </Modal.Body>
            <ToastContainer/>
        </Modal>
    );
}

export default ProfileModal;
