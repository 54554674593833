import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

export const AppLayoutContext = createContext();
export const LevelProvider = createContext();
export const Info = createContext()
function AppLayout() {
    const [currentLevel, setCurrentLevel] = useState(0);
    const [information, setInformation] = useState(null)
    const [render, setRender] = useState(0);
    const [time, setTime] = useState(0);
    return (
        <LevelProvider.Provider value={{ currentLevel, setCurrentLevel }}>
            <Info.Provider value={{ information, setInformation }}>
                <AppLayoutContext.Provider value={{ render, setRender, time, setTime }}>
                    <Outlet />
                </AppLayoutContext.Provider>
            </Info.Provider>
        </LevelProvider.Provider >
    )
}

export default AppLayout