import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from '../Service/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ProfileFotoModal({ show, handleClose, getMyInformation }) {

    const [selectedFile, setSelectedFile] = useState(null);


    const showSuccessToast = () => {
        toast.success('Muvaffaqiyatli!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      };
    
    
      const showErrorToast = () => {
        toast.error('Xato!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      };
    

    const handlePhotoChange = (e) => {
      setSelectedFile(e.target.files[0]);
    };
  
    const uploadPhoto = (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      axios.put('/user/updateAvatar', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          showSuccessToast()
          handleClose(); // Закрыть модальное окно после успешной загрузки
          setTimeout(() => {
            window.location.reload();
        }, 1000);
        })
        .catch((error) => {
          console.log(error);
          showErrorToast()
        });
    };
  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Rasm o`zgartirish</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={uploadPhoto}>
        <Form.Group controlId="formFile">
          <Form.Label>Rasm tanglang</Form.Label>
          <Form.Control type="file" onChange={handlePhotoChange} />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          O`zgartirish
        </Button>
      </Form>
    </Modal.Body>
    <ToastContainer/>
  </Modal>
  )
}

export default ProfileFotoModal