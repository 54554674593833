import React, { useEffect } from 'react';
import DemoTest from '../Components/DemoTest';
import RealTest from '../Components/RealTest';

function Test() {
    const token = localStorage.getItem('token');

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; 
        };

       
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div>
            {token ? <RealTest /> : <DemoTest />}
        </div>
    );
}

export default Test;
