import React from 'react'
import CollactionBySubjectItems from '../Components/CollactionBySubjectItems'
import Hero from '../Components/Hero'
import DemoCollactionBySubjectItems from '../Components/DemoCollactionBySubjectItems';
import FAQ from '../Components/FAQ';

function CollactionBySubject() {
  const token = localStorage.getItem('token');
  return (
    <div>
        <Hero/>
        {token ? <CollactionBySubjectItems/> : <DemoCollactionBySubjectItems/>}
        <FAQ/>
    </div>
  )
}

export default CollactionBySubject