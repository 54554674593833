import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Accordion, AccordionCollapse, AccordionItem } from 'react-bootstrap';
import '../Style/Header.css';
import Login from './Login';
import Register from './Register';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import axios from '../Service/axios';
import CONFIG from '../Service/Config';
import { Info } from '../layouts/AppLayout';
import { AppLayoutContext } from '../layouts/AppLayout';

function Header() {
    const [showModal, setShowModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [info, setInfo] = useState([]);
    const [ball, setBall] = useState([]);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const activeRegister = () => setRegisterModal(true);
    const closeRegister = () => setRegisterModal(false);
    const toggleAccordion = () => setIsAccordionOpen(prev => !prev);
    const { information } = useContext(Info);
    const { render, } = useContext(AppLayoutContext);
    const { time, setTime } = useContext(AppLayoutContext)
    const location = useLocation();

    const Ball = location.pathname === '/' || location.pathname === '/profil' || location.pathname === '/contact' || location.pathname === '/aboutUs' || location.pathname === '/result'

    useEffect(() => {

    }, [location.pathname])

    const FotoPerson = 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/925px-Unknown_person.jpg';

    const getMyInfo = () => {
        axios.get(`/user/getCurrenInformation/${0}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => {
                setInfo(response.data);
            })
            .catch((error) => {
                if (error.response.data.status === 401) {
                    localStorage.clear();
                }
            });
    }

    useEffect(() => {
        if (information !== null) {
            const getBall = async () => {
                try {
                    const response = await axios.get(`/user/getCurrenInformation/${information}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    setBall(response.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            getBall();
        }
    }, [information, render]);


    useEffect(() => {
        getMyInfo();
    }, []);

    useEffect(() => {
        let timer;
        if (typeof time === 'number' && time > 0) {
            timer = setInterval(() => {
                setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
        } else if (time === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [time, setTime]);



    const Token = localStorage.getItem('token');;

    return (
        <header>
            <div className='Container'>
                <div className='header__wrapper'>
                    <div className='header__grid'>
                        <div>
                            <NavLink to='/'>
                                <h1>IQ Test</h1>
                            </NavLink>
                        </div>
                        <nav>
                            <NavLink to='/'>Asosiy</NavLink>
                            <NavLink to='/contact'>Aloqa</NavLink>
                            <NavLink to='/aboutUs'>Biz haqimizda</NavLink>
                            <NavLink to='/result'>Natijalar</NavLink>
                        </nav>
                    </div>

                    <div className='header__grid'>
                        {information === null ? (
                            <div></div>
                        ) : (
                            <div className={`heaer__ball ${Ball ? 'dn' : ''}`}>
                                <h5 className='point'><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#1D4ED6" d="m5.825 21l1.625-7.025L2 9.25l7.2-.625L12 2l2.8 6.625l7.2.625l-5.45 4.725L18.175 21L12 17.275z"></path></svg>{ball.ball}</h5>
                                <h5 className='point'><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#1D4ED6" d="M218.1 167.2c0 13 0 25.6 4.1 37.4c-43.1 50.6-167.5 194.5-167.5 194.5l2.9 36.3s34.8 33 40 28c15.4-15 24.8-25.2 24.8-25.2l7.24-43.35l47.11-3.47l3.78-46.8l49.63-.95l.49-50.09l52.69 2.1l9-18.84c15.5 6.7 29.6 9.4 47.7 9.4c68.5 0 124-53.4 124-119.2S408.5 48 340 48s-121.9 53.4-121.9 119.2M406.85 144A38.85 38.85 0 1 1 368 105.15A38.81 38.81 0 0 1 406.85 144"></path></svg>{ball.key}</h5>
                            </div>
                        )}
                        {time > 0 && (
                            <h5 className='header__time'><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="#1D4ED6" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 4a1 1 0 0 0-1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V7a1 1 0 0 0-1-1"></path></g></svg> {`${time}`}</h5>
                        )}
                        {Token ? (
                            <NavLink to='/profil'>
                                <div className='header__profile'>
                                    <img src={info.avaName ? CONFIG.API_URL + info.avaName : FotoPerson} alt="Foto" />
                                </div>
                            </NavLink>
                        ) : (
                            <div className='header__btn'>
                                <button onClick={handleShow} className="btn btn-primary">Kirish</button>
                                <button onClick={activeRegister} className="btn btn-primary">Ro'yhatan o'tish</button>
                            </div>
                        )}
                        <button className='header__burger' onClick={toggleAccordion}>
                            {isAccordionOpen ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><path fill="currentColor" fillRule="evenodd" d="M1.707.293A1 1 0 0 0 .293 1.707L5.586 7L.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414-1.414L8.414 7l5.293-5.293A1 1 0 0 0 12.293.293L7 5.586z" clipRule="evenodd"></path></svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6h18M3 12h18M3 18h18"></path></svg>
                            )}
                        </button>
                    </div>
                </div>
                <div className='header__accrodion'>
                    <Accordion activeKey={isAccordionOpen ? "0" : null}>
                        <AccordionItem eventKey="0">
                            <AccordionCollapse eventKey="0">
                                <div className="accordion-body">
                                    <nav className='Mobile__nav'>
                                        <NavLink onClick={toggleAccordion} to='/'>Asosiy</NavLink>
                                        <NavLink onClick={toggleAccordion} to='/contact'>Aloqa</NavLink>
                                        <NavLink onClick={toggleAccordion} to='/aboutUs'>Biz haqimizda</NavLink>
                                        <NavLink onClick={toggleAccordion} to='/result'>Natijalar</NavLink>
                                    </nav>
                                    {Token ? (
                                        <div></div>
                                    ) : (
                                        <div className='header__btnM'>
                                            <button onClick={handleShow} className="btn btn-primary">Kirish</button>
                                            <button onClick={activeRegister} className="btn btn-primary">Ro'yhatan o'tish</button>
                                        </div>
                                    )}
                                </div>
                            </AccordionCollapse>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <Login show={showModal} handleClose={handleClose} />
            <Register show={registerModal} handleClose={closeRegister} />
        </header>
    );
}

export default Header;
