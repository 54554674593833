import React from 'react'
import ContactCard from '../Components/ContactCard'
import ContactForm from '../Components/ContactForm'
import Hero from '../Components/Hero'

function Contact() {
  return (
   <main>
    <Hero title='Savollaringiz bo’lsa murojaat qiling' text='O‘zingizning kreativ fikrlash, bilimlarni hayotga bog‘lay olish ko‘nikmalaringizni biz bilan rivojlantiring'/>
    <ContactCard/>
    <ContactForm/>
   </main>
  )
}

export default Contact