import React from 'react'
import '../Style/ContactForm.css'
import { Form, Button } from 'react-bootstrap'; // Importing React Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure Bootstrap CSS is imported
function ContactForm() {
  return (
    <section className='ContactForm'>
        <div className='Container'>
            <div className='ContactForm__wrapper'>
            <Form> {/* React Bootstrap Form component */}
          <h2 className="mb-4">Savollaringiz bo’lsa murojaat qiling</h2>
          
          {/* Name field */}
          <Form.Group controlId="formName" className="mb-3">
            <Form.Label>Ism</Form.Label>
            <Form.Control type="text" placeholder="Ismingizni kiriting" />
          </Form.Group>

          {/* Phone field */}
          <Form.Group controlId="formPhone" className="mb-3">
            <Form.Label>Telefon</Form.Label>
            <Form.Control type="tel" placeholder="Telefon raqamingizni kiriting" />
          </Form.Group>

          {/* Message field */}
          <Form.Group controlId="formMessage" className="mb-3">
            <Form.Label>Xabar</Form.Label>
            <Form.Control as="textarea" rows={4} placeholder="Xabaringizni yozing" />
          </Form.Group>

          {/* Submit button */}
          <Button variant="primary" type="submit">Yuborish</Button>
        </Form>
            </div>
        </div>
    </section>
  )
}

export default ContactForm