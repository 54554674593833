import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/ChapterCard.css';
import { Link, useParams } from 'react-router-dom';
import axios from '../Service/axios';
import ReactLoading from 'react-loading';
import { Info, LevelProvider } from '../layouts/AppLayout'; 
import { AppLayoutContext } from '../layouts/AppLayout';
import { useLocation } from 'react-router-dom';
function ChapterCard() {
    const { ID } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {  currentLevel, setCurrentLevel } = useContext(LevelProvider); // Исправлено использование контекста
    const {time, setTime} = useContext(AppLayoutContext)
    const location = useLocation();
    const subjectId = location.state?.subjectId;
    const {setInformation} = useContext(Info)


    
    const getTTL = (setTime, setCurrentLevel) => {
        axios.get('/attempts/getUserTTL', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then((response) => {
            setTime(response.data.object);
            const time = response.data.object
            if(time > 10){
                setCurrentLevel(0)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    useEffect(() => {
        const getLevels = async () => {
            try {
                const response = await axios.get(`/level/getAllByCollectionId/${ID}`);
                const levels = response.data;
                setInformation(subjectId)
                const firstUnresolved = levels.find(level => !level.isResolved);
                if (firstUnresolved) {

                } else if (levels.length > 0) {

                }
                setData(levels);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        getTTL(setTime, setCurrentLevel)
        getLevels();
    }, [ID, setTime, setCurrentLevel, setInformation, subjectId]);

    if (loading) {
        return (
            <div className='Loading__mb'>
                <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
            </div>
        );
    }
    if (!data || data.length === 0) {
        return (
            <div className='ChapterCard'>
                <div className='Container'>
                    <div className='Nores'>
                        <h2>Daraja yo'q</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <section className='ChapterCard'>
            <div className='Container'>
                <div className='ChapterCard__wrapper'>
                    {data.map((level, index) => {
                        const isActive = currentLevel === index;
                       const isLocked = currentLevel !== index || time > 0
                    
                        return (
                            <div key={index} className={`card-container ${isActive ? 'active' : 'inactive'}`}>
                                {!isLocked ? (
                                    <Link 
                                        to={`/test/${level.id}`}
                                        state={{ cardData: level, index: index, subjectId:subjectId }}
                                        className='active-link'
                                    >
                                        <Card 
                                            className="custom-card" 
                                            style={{ 
                                                width: '12rem', 
                                                borderRadius: '10px', 
                                                borderLeft: `5px solid blue`, 
                                                opacity: 1
                                            }}
                                        >
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-start">
                                                    <h2>{level.title}</h2>
                                                </div>
                                                <Card.Text className="text-muted">
                                                    {level.educationName}
                                                </Card.Text>
                                                <Card.Text style={{ color: 'blue', fontWeight: 'bold' }}>
                                                    {`Savolar soni ${level.questionCount} ta`}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                ) : (
                                    <Card 
                                        className="custom-card" 
                                        style={{ 
                                            width: '12rem', 
                                            borderRadius: '10px', 
                                            borderLeft: `5px solid gray`, 
                                            opacity: 0.5
                                        }}
                                    >
                                        <Card.Body>
                                            <div className="d-flex justify-content-between align-items-start">
                                                <h2>{level.title}</h2>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2m-6 9c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2M9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2z"></path>
                                                </svg>
                                            </div>
                                            <Card.Text className="text-muted">
                                                {level.educationName}
                                            </Card.Text>
                                            <Card.Text style={{ color: 'blue', fontWeight: 'bold' }}>
                                                {`Savolar soni ${level.questionCount} ta`}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default ChapterCard;
