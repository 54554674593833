import React, { useEffect, useState } from 'react';
import '../Style/HomeTest.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import axios from '../Service/axios';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
function DemoCollactionBySubjectItems() {


    const navigate = useNavigate();

    const Navigate = (id) => {
        navigate(`/chapter/${id}`)
    }
    const { ID } = useParams();
    const [collaction, setCollaction] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const getAllSubject = () => {
            axios.get(`/collection/getCollectionsBySubjectId/${ID}`, {
                params: { isDemo: true } 
              })
                .then((response) => {
                    setCollaction(response.data[0]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(()=>{
                    setLoading(false)
                })
        };

        // Call the function
        getAllSubject();
    }, [ID]); // Add ID to the dependency array
    if (loading) {
        return (
          <div  className='Loading__mb'>
            <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
          </div>
        );
      }

      if (!collaction || collaction.length === 0) {
        return (
            <div className='HomeTest'>
                <div className='Container'>
                <h2>
                    O'zingizni sinab ko'ring!
                </h2>
                    <div className='Nores'>
                    <h2>Collaction yo`q</h2> 
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className='HomeTest'>
            <div className='Container'>
                <h2>
                    O'zingizni sinab ko'ring!
                </h2>
                <div className='HomeTest__swiper pc'>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={10}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={()=>Navigate(i.id)}>
                                <div className='HomeTest__Card DemoCard2'>
                                    <div>
                                        <h3>
                                            {i.title}
                                        </h3>
                                        <span>
                                            {i.description}
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}

                        {/* Add additional SwiperSlide elements as needed */}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper dec'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                         {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={()=>Navigate(i.id)}>
                                <div className='HomeTest__Card DemoCard2'>
                                    <div>
                                        <h3>
                                            {i.title}
                                        </h3>
                                        <span>
                                            {i.description}
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* Add additional SwiperSlide elements as needed */}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper mb'>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={50}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                          {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={()=>Navigate(i.id)}>
                                <div className='HomeTest__Card DemoCard2'>
                                    <div>
                                        <h3>
                                            {i.title}
                                        </h3>
                                        <span>
                                            {i.description}
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* Add additional SwiperSlide elements as needed */}
                    </Swiper>
                </div>
                <div className='HomeTest__swiper lc'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        navigation={true}
                        loop={false} // Disable looping
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                          {collaction?.map((i, index) => (
                            <SwiperSlide key={index} onClick={()=>Navigate(i.id)}>
                                <div className='HomeTest__Card DemoCard2'>
                                    <div>
                                        <h3>
                                            {i.title}
                                        </h3>
                                        <span>
                                            {i.description}
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))} 
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
export default DemoCollactionBySubjectItems;
